import { object, array, string, InferType } from 'yup'

import { MedicationSchema } from 'schemas/MedicalHistory'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  medication: MedicationSchema.required(),
})

type RequestParams = InferType<typeof Schema>

export const url = ({ memberId }: RequestParams) => `/v1/history/personal/${memberId}/medications`

export const getBody = (params: RequestParams) => {
  const medication = MedicationSchema.cast(params.medication, { stripUnknown: true })

  return { medications: [medication] }
}
