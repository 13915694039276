import { object, array, string, InferType } from 'yup'

import { MedicationSchema, MedicationSchemaV2 } from 'schemas/MedicalHistory'
import { formatMedicationDateToApi } from 'util/patient'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  medication: MedicationSchemaV2.required(),
})

type RequestParams = InferType<typeof Schema>

export const url = ({ memberId }: RequestParams) => `/v2/history/personal/${memberId}/medications`

export const getBody = (params: RequestParams) => {
  const { direction, strengthAndUnit, startDate, endDate, dispensableDrugId, ...medication } = MedicationSchemaV2.cast(
    params.medication,
    { stripUnknown: true },
  )

  return {
    medications: [
      {
        ...medication,
        direction: direction || null,
        strengthAndUnit: strengthAndUnit || null,
        startDate: formatMedicationDateToApi(startDate) || null,
        endDate: formatMedicationDateToApi(endDate) || null,
        dispensableDrugId: dispensableDrugId || null,
      },
    ],
  }
}
