import makeQuery from 'api/makeQuery'
import { debounce } from 'lodash'
import { makeAutoObservable } from 'mobx'
import type { Referral as ReferralType, ReferralTimeframe, ServiceType } from 'types/Visit/index'
import Visit from 'types/Visit/index'

const ALLOWED_KEYS = ['careCoordinatorNeeded', 'notes', 'referralId', 'serviceType', 'timeframe'] as const

class Referral {
  private root: any
  notes = ''
  referralId = ''
  serviceType: ServiceType | '' = ''
  timeframe: ReferralTimeframe | '' = ''
  careCoordinatorNeeded: boolean | undefined = undefined
  requested: boolean | undefined = undefined

  private syncDebounced = debounce(async () => {
    if (this.correct && this.timeframe && this.serviceType) {
      const visitRecord = await makeQuery('putVisitReferral', {
        visitId: this.root.visitId,
        ...this.toJSON(),
      })
      this.root.merge(visitRecord)
    }
  }, 500)

  constructor(root: any, initialValues: ReferralType | undefined) {
    this.root = root
    if (initialValues) {
      this.merge(initialValues)
    }
    makeAutoObservable<Referral, 'syncDebounced'>(this, {
      toJSON: false,
      destroy: false,
      syncDebounced: false,
    })
  }

  get empty() {
    return !this.referralId
  }

  get notesCorrect() {
    return this.notes.length > 10
  }

  get correct() {
    return this.requested !== undefined && this.notesCorrect && this.careCoordinatorNeeded !== undefined
  }

  setNotes(notes: string) {
    this.notes = notes
    this.syncDebounced()
  }

  setServiceType(serviceType: ServiceType) {
    this.serviceType = serviceType
    this.syncDebounced()
  }

  setTimeframe(timeframe: ReferralTimeframe) {
    this.timeframe = timeframe
    this.syncDebounced()
  }

  toJSON() {
    return Object.fromEntries(ALLOWED_KEYS.map((k) => [k, this[k]]))
  }

  destroy() {
    this.syncDebounced.flush()
  }

  merge(v: ReferralType) {
    let changed = false
    for (const key of ALLOWED_KEYS) {
      if (key in v) {
        if (this[key] !== v[key]) {
          changed = true
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[key] = v[key]
      }
    }
    if (changed) {
      this.syncDebounced()
    }
  }

  clear() {
    this.referralId = ''
    this.serviceType = ''
    this.timeframe = ''
    this.notes = ''
  }

  *delete() {
    const visitRecord: Visit = yield makeQuery('deleteVisitReferral', {
      visitId: this.root.visitId,
    })
    this.clear()
    this.root.merge(visitRecord)
  }

  setRequested(v: boolean) {
    this.requested = v
    if (!this.requested && this.referralId) {
      this.delete()
    }
  }

  setCareCoordinatorNeeded(v: boolean) {
    this.careCoordinatorNeeded = v
    this.syncDebounced()
  }
}

export default Referral
