import makeQuery from 'api/makeQuery'
import { PHONE_CALL_STATUS } from 'constants/phoneCallStatuses'
import { action, flow, makeObservable } from 'mobx'
import PhoneCallBase from 'models/PhoneCallBase'
import GlobalEvents from 'singletons/GlobalEvents'
import User from 'singletons/User'

class PhoneCall extends PhoneCallBase {
  constructor(visit) {
    super(visit)
    makeObservable(this, {})
  }

  *start() {
    this.status = PHONE_CALL_STATUS.INPROGRESS
    try {
      if (this.visit && !this.visit.started) {
        yield this?.visit.start()
      }
      const providerPhone = User.provider?.preferredPhone?.number
      if (!providerPhone) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(User.provider)
        }
        throw new Error('Provider phone is empty')
      }
      const memberPhone = this.visit.phone?.number

      if (!memberPhone) {
        throw new Error('Member phone is empty')
      }
      yield makeQuery('postConference', {
        visitId: this.visit.visitId,
        providerPhone,
        memberPhone,
      })
      GlobalEvents.emit('visitStartPhone', {
        status: 'success',
      })
      this.status = PHONE_CALL_STATUS.CONNECTED
    } catch (e) {
      GlobalEvents.emit('visitStartPhone', {
        status: 'error',
        description: 'Unable to start conference',
        visit: this.visit,
      })
      console.log(e)
      this.status = PHONE_CALL_STATUS.OFFLINE
    }
  }

  end() {
    this.status === PHONE_CALL_STATUS.OFFLINE
  }
}

export default PhoneCall
