import { object, string } from 'yup'
import { when } from 'mobx'
import pick from 'util/pick'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visit: object(),
  scheduledAt: string(),
})

export const url = ({ visit: { providerId } }) => `/v1/providers/${providerId}/followup`

const BASIC_VISIT_FIELDS = [
  'visitType',
  'memberDob',
  'memberLastName',
  'memberFirstName',
  'providerName',
  'providerId',
  'modality',
  'reasonForVisit',
  'timezone',
  'locationCountry',
  'location',
  'memberId',
]

const BASIC_VISIT_PHONE_FIELDS = ['number', 'type']

const BASIC_VISIT_PHARMACY_FIELDS = ['name', 'externalId']

const BASIC_MEMBER_EXTENDED_DATA_FIELDS = ['clientId', 'groupId']

export const getBody = async ({ visit, scheduledAt }) => {
  await when(() => !!visit.memberExtendedData)
  const followup = {
    ...pick(visit, BASIC_VISIT_FIELDS),
    ...pick(visit.memberExtendedData?.privateData.member, BASIC_MEMBER_EXTENDED_DATA_FIELDS),
    followupToVisitId: visit.visitId,
    scheduledAt,
    providerTimezone: visit.provider.timezone,
    phone: pick(visit.phone, BASIC_VISIT_PHONE_FIELDS),
  }

  if (visit.isPrescribable) {
    followup.pharmacy = pick(visit.pharmacy, BASIC_VISIT_PHARMACY_FIELDS)
  }
  return { followup }
}
