import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  medicationId: string().required(),
})

export const url = ({ memberId, medicationId }) => `/v1/history/personal/${memberId}/medications/${medicationId}`
