import VisitBase from '../VisitBase'
import {
  makeObservable,
  onBecomeObserved,
  override,
  reaction,
  comparer,
  action,
  runInAction,
  computed,
  flow,
} from 'mobx'
import mapValues from 'lodash/mapValues'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import omit from 'lodash/omit'

import makeQuery from 'api/makeQuery'
import Pharmacies from 'singletons/Pharmacies'
import User from 'singletons/User'
import Providers from 'singletons/Providers'
import GlobalEvents from 'singletons/GlobalEvents'
import pick from 'util/pick'
import isVisitActual from 'util/isVisitActual'
import { BEFOREHAND_DURATION, VISIT_PAGE_BLOCK_TIMER } from 'constants/timeValues'
import Availability from 'models/Provider/Availability'
import Dxs from './Dxs'
import Notes from './Notes'
import DischargeInstructions from './DischargeInstructions'
import Restriction from './Restriction'
import Images from './Images'
import IntakeQuestionnaire from './IntakeQuestionnaire'
import Rxs from './Rxs'
import Chat from './Chat'
import MbcAssessmentScores from './MbcAssessmentScores'
import Referral from './Referral'

import { SETTIMEOUT_MAX_VALUE } from 'constants/timers'
import PhoneCall from 'models/PhoneCall'
import NewPhoneCall from 'models/NewPhoneCall'
import CrisisEscalation from 'models/Provider/visits/PendingVisit/CrisisEscalation'
import { isSuccessVisit } from 'constants/visit-types'

const LIMITED_OPTIONS_BY_SERVICE_TYPE = {
  medical: ['timelycare_medical'],
  medical_now: ['timelycare_medical'],
  therapy: ['timelycare_scheduled_counseling'],
  psychiatry: ['timelycare_psychiatry'],
  hc: ['timelycare_health_coaching'],
  needs: ['timelycare_basic_needs'],
  success_coaching: ['timelycare_success_coaching'],
  success_coaching_now: ['timelycare_success_coaching'],
}

const UNLIMITED_OPTIONS = [
  'covid19_protocol',
  'campus_mental_health_services',
  'campus_medical_services',
  'external_emergency_room',
  'external_mental_health_hospital',
  'external_mental_health_intensive_outpatient_partial_hospital_program',
  'external_urgent_care',
  'external_mental_health_care_provider',
  'external_pediatrician',
  'external_primary_care_provider',
  'external_psychiatry_in_person',
  'external_obgyn',
  'external_dermatology',
  'external_gastroenterology',
  'external_orthopedics',
  'external_ophthalmology',
  'timelycare_care_coordination',
]

const EMPTY_ARRAY = []

class PendingVisit extends VisitBase {
  static INITIAL_FIELDS = {
    ...super.INITIAL_FIELDS,
    /* these fields appear after status becomes in-progress */
    visitId: '',
    providerName: '',
    pharmacy: null,

    /*
      must be undefined, because true/false will not give us opportunity to detect uninitialized state.
      This is used in questionnaire fetching
    */
    assessmentCompleted: undefined,
    followup: true,
    followupAt: null,
    followupInstructions: '',
    medicationReconciliation: undefined,

    /* these fields are merged later */
    assessment: null,
    chatId: '',
    paymentTransactionId: '',
    statusReason: '',
    claimedId: '',
    claimedAt: '',
    cancelledAt: '',
    completedAt: '',
    intakes: null,
    gad7: null,
    phq9: null,
    safetyPlan: null,
    clientCardActiveVersion: 0,
    clientCardExistingVersions: [],
  }

  get clientScheduledType() {
    return this.__shouldBeDisplayedAsShownAsActiveVisit ? 'scheduled-shown-as-active' : this.scheduledType
  }

  get shouldDisableStarting() {
    return this.clientScheduledType === 'scheduled'
  }

  get extendedPharmacyInfo() {
    const externalId = this.pharmacy?.externalId
    return externalId ? Pharmacies.get(externalId) : null
  }

  get availableServiceTypes() {
    const { memberServices, visitType } = this

    if (!memberServices) {
      return EMPTY_ARRAY
    }

    const limitedSet = new Set()

    for (const service of memberServices) {
      LIMITED_OPTIONS_BY_SERVICE_TYPE[service.serviceType]?.forEach((s) => limitedSet.add(s))
    }

    const list = [...Array.from(limitedSet), ...UNLIMITED_OPTIONS]

    return visitType === 'hc' || (visitType === 'therapy' && this.scheduledType === 'now')
      ? list.filter((v) => v !== 'timelycare_psychiatry' && v !== 'external_psychiatry_in_person')
      : list
  }

  scheduledTherapyIntakeNote = null

  constructor(valuesObj) {
    super(valuesObj)

    if (valuesObj.followupAt) {
      this.followupAt = new Date(valuesObj.followupAt)
    }

    this.allowStartVisitTimer = 0
    this.visitPageDisableTimer = 0
    this.shouldDisableVisitPage = false
    this.wasCancelledByProvider = false
    this.cancellationReasons = []
    this.scheduledTherapyIntakeNote = null
    /*
      This is useful only in conjunction with this.scheduledType.
      Normally should not be used directly.
    */
    this.__shouldBeDisplayedAsShownAsActiveVisit = false
    this.isSubmittingFollowUp = false

    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
      shouldDisableVisitPage: true,
      wasCancelledByProvider: true,
      availableServiceTypes: true,
      clientCardActiveVersion: true,
      clientCardExistingVersions: true,
      __shouldBeDisplayedAsShownAsActiveVisit: true,
      shouldShowFollowupSection: true,
      isSubmittingFollowUp: true,
      merge: override,
      putVisitDischarge: false,
      updateFollowupInstructions: action,
      clearFollowupAt: action,
      setIsSubmittingFollowUp: action,
      sendBackToQueueForbidden: true,
      isSuccess: computed,
      observableDischargeFields: computed({ equals: comparer.structural }),
      fetchCancellationReasons: flow,
      memberExtendedData: computed({ equals: comparer.structural }),
      isPrescribable: computed,
      canAddMedication: computed,
      shouldAskScheduledTherapyIntakeNote: computed,
      isScheduledTherapyIntakeNoteSelected: computed,
      scheduledTherapyIntakeNote: true,
      saveScheduledTherapyIntakeNote: action,
      questionnaireVisitType: computed,
      allMemberAllergies: computed,
      shouldShowNewPatientAllergiesBanner: computed,
      shouldShowMedicationReconciliationSection: computed,
      updateMedicationReconciliation: action,
    })

    this.availability = new Availability(this)
    this.crisisEscalation = new CrisisEscalation(this)
    this.rxs = new Rxs(this, valuesObj?.rxs)
    this.dxs = new Dxs(this, valuesObj?.dxs)
    this.notes = new Notes(this, valuesObj?.notes)
    this.dischargeInstructions = new DischargeInstructions(this, valuesObj?.dischargeInstructions)
    this.restriction = new Restriction(this, valuesObj?.restriction || null)
    this.images = new Images(this)
    this.intakeQuestionnaire = new IntakeQuestionnaire(this)
    this.chat = new Chat(this)
    this.gad7Data = new MbcAssessmentScores(this, 'gad7')
    this.phq9Data = new MbcAssessmentScores(this, 'phq9')
    this.phoneCall = !User.isTwilioVoiceCallEnabled ? new PhoneCall(this) : new NewPhoneCall(this)
    this.referral = new Referral(this, valuesObj?.referral)
    this.clientCardActiveVersion = 0
    this.disposeImageObserver = onBecomeObserved(this.images, 'list', () => this.images.refetch())

    if (this.intakeVisible) {
      this.disposeQuestionnaireObserver = onBecomeObserved(this.intakeQuestionnaire, 'questions', () =>
        this.intakeQuestionnaire.refetch(),
      )
    } else {
      this.disposeQuestionnaireObserver = null
    }

    /* used in past visits; causes a lot of parallel queries */
    if (!this.rxs) {
      this.disposeDirtyRxsFetcher = onBecomeObserved(this.rxs, 'list', () => this.getExtendedRecordWithoutFollowUp())
    } else {
      this.disposeDirtyRxsFetcher = () => null
    }

    this.disposeWasCancelledByProvider = reaction(
      () => this.status,
      (status, prevStatus) => {
        if (prevStatus === 'cancelled') {
          this.wasCancelledByProvider = false
        }
      },
    )

    this.disposeAutoUpdate = reaction(
      () => {
        switch (this.status) {
          case 'completed':
          case 'cancelled':
            return null
          default:
            return this.observableDischargeFields
        }
      },
      (v) => v && this.putVisitDischarge(this.followupAt),
      { delay: 3000 },
    )

    this.disposeScheduledTimers = reaction(
      () => !this.claimed && this.status === 'pending' && this.scheduledType === 'scheduled',
      (shouldSetTimers) => {
        runInAction(() => {
          if (shouldSetTimers) {
            const now = Date.now()
            const untilScheduled = new Date(this.scheduledAt) - now

            const timeLeftForClaiming = untilScheduled - BEFOREHAND_DURATION
            const timeLeftForVisitView = untilScheduled - VISIT_PAGE_BLOCK_TIMER

            if (timeLeftForClaiming > 0) {
              this.__shouldBeDisplayedAsShownAsActiveVisit = false
              if (timeLeftForClaiming < SETTIMEOUT_MAX_VALUE) {
                console.log(
                  `%cShould convert scheduled visit ${this.pendingVisitId} in: ${formatDistanceStrict(
                    now + timeLeftForClaiming,
                    now,
                  )}`,
                  'color: brown; font-size: 1.2em; font-weight: 600;',
                )
                this.scheduledToActiveConversionTimer = setTimeout(
                  () =>
                    runInAction(() => {
                      this.__shouldBeDisplayedAsShownAsActiveVisit = true
                    }),
                  timeLeftForClaiming,
                )
              }
            } else {
              this.__shouldBeDisplayedAsShownAsActiveVisit = true
              console.log(
                `%cScheduled visit ${
                  this.pendingVisitId
                } will be rendered as an active visit. scheduledAt expired: ${formatDistanceStrict(
                  now + timeLeftForClaiming,
                  now,
                )} ago`,
                'color: brown; font-size: 1.2em; font-weight: 600;',
              )
            }

            if (timeLeftForVisitView > 0) {
              this.shouldDisableVisitPage = true
              if (timeLeftForVisitView < SETTIMEOUT_MAX_VALUE) {
                this.visitPageDisableTimer = setTimeout(
                  () =>
                    runInAction(() => {
                      this.shouldDisableVisitPage = false
                    }),
                  timeLeftForVisitView,
                )
              }
            }
          } else {
            clearTimeout(this.scheduledToActiveConversionTimer)
            clearTimeout(this.visitPageDisableTimer)
            this.shouldDisableVisitPage = false
            this.__shouldBeDisplayedAsShownAsActiveVisit = false
          }
        })
      },
      { fireImmediately: true },
    )
  }

  // TODO: smart for in
  merge(valuesObj) {
    super.merge(valuesObj)

    if (valuesObj) {
      if (!valuesObj.providerId) {
        /*
          providerId is removed from record on backend.
          Without this unclaim event does not work
        */
        this.providerId = ''
      }
      if (valuesObj.followupAt) {
        this.followupAt = new Date(valuesObj.followupAt)
      }
      if (valuesObj.dxs) this.dxs.replace(valuesObj.dxs)
      if (valuesObj.notes) this.notes.replace(valuesObj.notes)
      if (valuesObj.dischargeInstructions) this.dischargeInstructions.replace(valuesObj.dischargeInstructions)
      if (valuesObj.restriction?.restrictions) this.restriction.updateRestrictions(valuesObj.restriction?.restrictions)
      if (valuesObj.restriction?.resumeDate) this.restriction.updateResumeDate(valuesObj.restriction?.resumeDate)
      if (valuesObj.images) this.images.updateImages(valuesObj.images)
      if (valuesObj.referral) this.referral.merge(valuesObj.referral)
      if (valuesObj.intakeQuestionnaire) {
        this.intakeQuestionnaire.replace(valuesObj.intakeQuestionnaire)
      }
      if (valuesObj.rxs) {
        /*
          TODO:
            ask steven to ALWAYS return array here; html string is returned for mark.huebel provider account
        */
        if (Array.isArray(valuesObj.rxs)) {
          this.rxs.replace(valuesObj.rxs)
        }
      }
      if (valuesObj.medicationReconciliation) this.medicationReconciliation = valuesObj.medicationReconciliation
    }
  }

  async fetchClientCardActiveVersion() {
    const { activeVersion, existingVersions } = await makeQuery('getCareProtocolVersion', {
      filter: {
        group_id: this.groupId,
        client_id: this.clientId,
      },
    })
    runInAction(() => {
      this.clientCardActiveVersion = activeVersion
      this.clientCardExistingVersions = existingVersions
    })
  }

  clearFollowupAt() {
    this.followupAt = null
  }

  setIsSubmittingFollowUp(isSubmittingFollowUp) {
    this.isSubmittingFollowUp = isSubmittingFollowUp
  }

  async updateFollowupAt(date) {
    this.setIsSubmittingFollowUp(true)
    /*
      TODO: Do I need to use response value here?
    */
    await makeQuery('postFollowupVisit', {
      visit: this,
      scheduledAt: date.toISOString(),
    })
    await this.putVisitDischarge(date)
    this.setIsSubmittingFollowUp(false)
  }

  async putVisitDischarge(followupAtDate) {
    const { restriction, followupInstructions, ...discharge } = this.observableDischargeFields

    /* TODO: DEBUG */
    if (!followupInstructions) {
      console.log('No followup!', this)
    }

    if (restriction.resumeDate) {
      discharge.restriction = restriction
    }
    const visitRecord = await makeQuery('putVisitDischarge', {
      providerId: User.providerId,
      visitId: this.visitId,
      followup: true,
      /* sometimes null gets here */
      followupInstructions: followupInstructions || '',
      followupAt: followupAtDate?.toISOString() ?? null,
      ...discharge,
    })

    this.merge(visitRecord)
  }

  destroy() {
    clearTimeout(this.scheduledToActiveConversionTimer)
    clearTimeout(this.visitPageDisableTimer)
    this.availability.destroy()
    this.dxs.destroy()
    this.notes.destroy()
    this.rxs.destroy()
    this.intakeQuestionnaire.destroy()
    this.chat.destroy()
    this.disposeScheduledTimers()
    this.disposeAutoUpdate()
    this.disposeImageObserver()
    this.disposeDirtyRxsFetcher()
    this.disposeWasCancelledByProvider()
    this.referral.destroy()
    if (this.disposeQuestionnaireObserver) {
      this.disposeQuestionnaireObserver()
    }
  }

  async start() {
    if (this.started) {
      return
    }
    switch (this.status) {
      case 'cancelled':
        throw new Error(`Impossible to start visit with status: ${this.status}`)
      default: {
        const { visit, pendingVisit } = await makeQuery('putStartPendingVisit', {
          providerId: User.providerId,
          visitId: this.pendingVisitId,
        })

        GlobalEvents.emit('startPendingVisit', {
          status: 'success',
          visitType: visit?.visitType,
        })

        /* merge order is important */
        this.merge({
          ...pendingVisit,
          ...visit,
        })
      }
    }
  }

  /* will be used as event handler, so binding */
  claim = async () => {
    if (process.env.NODE_ENV !== 'production' && this.claimed) {
      throw new Error('Trying to claim already claimed visit')
    }

    const pendingVisitRecord = await makeQuery('putClaimPendingVisit', {
      providerId: User.providerId,
      visitId: this.pendingVisitId,
      visitType: this.visitType,
    })

    if (process.env.NODE_ENV !== 'production') {
      console.log('Visit was claimed; pending visit record: ', pendingVisitRecord)
    }

    this.merge(pendingVisitRecord)
  }

  /*
    Returns PendingVisitRecord;
    sets status to 'completed'
  */
  async submit() {
    this.phoneCall?.end?.()
    const pendingVisitRecord = await makeQuery('putCompleteVisit', {
      visitId: this.visitId,
    })
    this.merge(pendingVisitRecord)

    if (process.env.NODE_ENV !== 'production') {
      console.log('submit pendingVisitRecord', pendingVisitRecord)
    }
  }

  checkIfExtendedRecordExists() {
    if (!this.followupInstructions) {
      this.getExtendedRecord()
    }
  }

  /*
    TODO: Figure out a cleaner way to not call getExtendedRecord when pulling up prescriptions tab when there are no rx changes.
    This function below was changed from the orignial getExtendedRecord so that the dischargeInstructions don't get overwritten.
    The bug that this was a fix for was when a provider filled in discharge and then quickly switched over to the prescriptions tab.
  */
  async getExtendedRecordWithoutFollowUp() {
    const joinedVisitRecord = await User.getVisitByPendingVisitId(this.pendingVisitId)
    const normalizedJoinedVisitRecord = omit(joinedVisitRecord, ['followupInstructions', 'dischargeInstructions'])
    this.merge(normalizedJoinedVisitRecord)
  }

  async getExtendedRecord() {
    const joinedVisitRecord = await User.getVisitByPendingVisitId(this.pendingVisitId)

    this.merge(joinedVisitRecord)
  }

  async startVideoCall() {
    return await makeQuery('getStartVideoCallV2', {
      providerId: User.providerId,
      visitId: this.visitId,
    })
  }

  *fetchCancellationReasons() {
    const cancellationReasons = yield makeQuery('getCancellationReasons', {
      filter: {
        visit_type: this.visitType,
      },
    })
    this.cancellationReasons = cancellationReasons
  }

  /*
    Returns PendingVisitRecord;
  */
  async cancel(reason, reasonCode, shouldPutBackToQueue, memberFullName, cancellationNotes) {
    const pendingVisitRecord = await makeQuery('putPendingVisitStatus', {
      visitId: this.pendingVisitId,
      cancelledBy: !shouldPutBackToQueue ? 'provider' : undefined,
      status: shouldPutBackToQueue ? 'pending' : 'cancelled',
      statusReason: reason,
      statusReasonCode: reasonCode,
      cancellationNotes: cancellationNotes || null,
    })

    GlobalEvents.emit('cancelPendingVisit', {
      status: 'success',
      memberFullName,
      reason,
      visitType: pendingVisitRecord?.visitType,
    })

    runInAction(() => {
      this.wasCancelledByProvider = true
      if (shouldPutBackToQueue) {
        /*
              Mongo will completely remove providerId key from respnse,
              so merge will not erase it. But we need empty providerId here.
          */
        pendingVisitRecord.providerId = ''
      }

      this.merge(pendingVisitRecord)

      if (process.env.NODE_ENV !== 'production') {
        console.log('cancel pendingVisitRecord', pendingVisitRecord)
      }
    })
  }
  /*
    Returns PendingVisitRecord;
  */
  async saveForLater() {
    this.phoneCall?.end()
    const pendingVisitRecord = await makeQuery('putPendingVisitStatus', {
      visitId: this.pendingVisitId,
      status: 'in-review',
    })

    GlobalEvents.emit('saveVisitForLater', {
      status: 'success',
      visitType: pendingVisitRecord?.visitType,
    })

    this.merge(pendingVisitRecord)

    if (process.env.NODE_ENV !== 'production') {
      console.log('in-review pendingVisitRecord', pendingVisitRecord)
    }
  }

  async putPharmacy(pharmacyId, name) {
    const { visit, ...restPendingVisit } = await makeQuery('putVisitPharmacy', {
      visitId: this.visitId,
      pharmacyId,
      name,
    })

    /* merge order is important */
    this.merge({ ...restPendingVisit, ...visit })
  }

  /*
      visit and pendingVisit records are merged in this model.
      safetyPlan is only present on visit (not pendingVisit) record after visit start (sched couns, talkNow, psych).
      safetyPlan.attestation is required to complete a visit when member answers phq-9 q9 > 0 (not none at all),
      or an existing CHANGED (changes tracked on BE) safetyPlan is present.
  */
  async updateSafetyPlanAttestation(attested) {
    const visitRecord = await makeQuery('putSafetyPlan', {
      visitId: this.visitId,
      attestation: attested,
    })
    this.merge(visitRecord)
  }

  updateFollowupInstructions(followupInstructions) {
    this.followupInstructions = followupInstructions
  }

  get shouldShowMedicationReconciliationSection() {
    return User.isMedicationSafteyPhase1Enabled && ['medical', 'medical_now', 'psychiatry'].includes(this.visitType)
  }

  updateMedicationReconciliation(data) {
    this.medicationReconciliation = data
  }

  get observableDischargeFields() {
    return {
      followupInstructions: this.followupInstructions,
      restriction: {
        restrictions: this.restriction.list.map((r) => r.trim()),
        resumeDate: this.restriction.resumeDate,
      },
      dischargeInstructions: this.dischargeInstructions.list.map((instruction) =>
        pick(instruction, ['documentId', 'filename', 'name']),
      ),
      medicationReconciliation: this.medicationReconciliation || null,
    }
  }

  get intakeVisible() {
    return this.visitType === 'therapy' || this.visitType === 'psychiatry'
  }

  get isSuccess() {
    return isSuccessVisit(this.visitType)
  }

  get sendBackToQueueForbidden() {
    return this.status !== 'in-progress' || this.scheduledType !== 'now'
  }

  get shouldHideClock() {
    return this.status !== 'in-review' && !(this.status === 'pending' && this.scheduledType === 'now')
  }

  get canBeEditable() {
    return this.status === 'in-progress' || this.status === 'in-review'
  }

  get showChatWindow() {
    return this.status === 'in-progress' || this.status === 'in-review'
  }

  get waitTime() {
    return this.sentToReviewAt || this.createdAt
  }

  get wasSentToReview() {
    return !!this.sentToReviewAt
  }

  get shouldShowFollowupSection() {
    return !this.paymentTransactionId && this.scheduledType === 'scheduled'
  }

  get remainingVisits() {
    return this.memberExtendedData?.privateData?.member?.visitLimits[this.visitType]?.remainingVisits
  }

  get currentVisitHardStop() {
    return this.memberServices?.find((service) => service.serviceType === this.visitType)?.hardStop
  }

  get memberHasReachedHardStop() {
    return this.currentVisitHardStop && this.remainingVisits <= 0
  }

  /*
      visit and pendingVisit records are merged in this model.
      some fields, like assessmentCompleted AND safetyPlan, come from visit record,
      which may be absent/not loaded.
  */
  get visitRecordLoaded() {
    return !!this.visitId
  }

  get isCancelPossible() {
    switch (this.status) {
      case 'cancelled':
      case 'in-review':
        return true
      case 'completed':
        return false
      default:
        return true
    }
  }

  get isLocationAbroad() {
    return this.locationCountry && this.locationCountry !== 'US'
  }

  get isScheduledTherapyVisit() {
    return this.visitType === 'therapy' && this.scheduledType === 'scheduled'
  }

  get isNowTherapyVisit() {
    return this.visitType === 'therapy' && this.scheduledType === 'now'
  }

  get shouldAskScheduledTherapyIntakeNote() {
    return !this.assessment?.questionnaireId && this.isScheduledTherapyVisit && User.isScDocumentationUpdatesEnabled
  }

  get isScheduledTherapyIntakeNoteSelected() {
    return typeof this.scheduledTherapyIntakeNote === 'boolean'
  }

  get questionnaireVisitType() {
    const defaultQuestionnaireVisitType =
      this.visitType === 'therapy' && this.scheduledType === 'now' ? 'therapy_now' : this.visitType

    if (this.shouldAskScheduledTherapyIntakeNote) {
      return this.isScheduledTherapyIntakeNoteSelected
        ? this.scheduledTherapyIntakeNote
          ? 'therapy_scheduled_intake'
          : 'therapy_scheduled_in_progress'
        : null
    }

    return this.isLocationAbroad && defaultQuestionnaireVisitType === 'therapy_now'
      ? 'therapy_now_abroad'
      : defaultQuestionnaireVisitType
  }

  saveScheduledTherapyIntakeNote = (value) => {
    this.scheduledTherapyIntakeNote = value
    this.intakeQuestionnaire.refetch()
  }

  /*
    Right now can't be joined with canAddMedication. Mark's quote:
    'There have been a couple visits that haven't had a pharmacy for one reason or another
    so we'd probably want keep those checks separate'
  */
  get isPrescribable() {
    return (
      (this.visitType === 'medical' || this.visitType === 'psychiatry') && !User.isHidePrescriptionsFeatureFlagEnabled
    )
  }

  get isActual() {
    return isVisitActual(this)
  }

  get isBelongsToProvider() {
    return this.providerId && this.providerId === User.providerId
  }

  get canEditPsychotherapyNotes() {
    return this.isBelongsToProvider && (this.visitType === 'therapy' || this.visitType === 'hc')
  }

  get isViewable() {
    return this.isBelongsToProvider && this.isActual
  }

  get canAddMedication() {
    return this.isPrescribable && !!this.pharmacy?.externalId
  }

  get shouldSendActiveFlagOnCareProtocols() {
    return this.clientCardActiveVersion > 1 ? true : this.clientCardExistingVersions.length > 1
  }

  get provider() {
    return Providers.get(this.providerId)
  }

  get isVisitNoteHidden() {
    return this.isScheduledTherapyVisit && !this.notes.history?.description && User.isScDocumentationUpdatesEnabled
  }

  get isVisitNoteRequired() {
    return (
      this.visitType === 'hc' ||
      this.isNowTherapyVisit ||
      // TODO: Remove line below when removing isScDocumentationUpdatesEnabled feature flag.
      // Scheduled Therapy visits should not have the visit note required when the flag is removed.
      (this.isScheduledTherapyVisit && !User.isScDocumentationUpdatesEnabled)
    )
  }

  get allMemberAllergies() {
    return this.memberExtendedData?.privateData?.allergies
  }

  get shouldShowNewPatientAllergiesBanner() {
    return !!User.isMedicationSafteyPhase1Enabled && !this.isSuccess
  }
}

export default PendingVisit
