import { object, string, number, mixed, boolean, lazy } from 'yup'
import { RxDaysSupplySchema } from './NewPrescription'

const SingleStrengthSchema = object().shape({
  unit: string().notRequired().nullable(),
  ndc: string().required('Required'),
})

const SingleMedicationNameSchema = object().shape({
  name: string().trim().required('Required'),
})

export const BaseRxSchema = object().shape({
  substitutions: boolean().required('Required'),
  refills: number()
    .typeError('Must be a number')
    .integer('Must be a whole number')
    .min(0, 'Cannot be negative')
    .max(99, 'Too many')
    .required('Required'),
  daysSupply: lazy((val) =>
    val === '' || val === null || val === undefined
      ? string().notRequired().nullable()
      : RxDaysSupplySchema,
  ),
  directions: string().trim().min(1, 'Too short').max(140, 'Too long').required('Required'),
  quantity: number()
    .typeError('Must be a number')
    .transform((value) => Number(value))
    .min(0, 'Minimum quantity is 0')
    .test('test-name', 'Must be under 10 digits', function (value) {
      const { path, createError } = this
      const stringVal = `${value}`.replace('.', '')
      const result = stringVal.length <= 10
      if (!result) createError({ path, message: 'Must be under 11 digits' })
      return result
    })
    .when({
      is: (quantity) => quantity === Math.round(quantity),
      then: number().integer(),
    })
    .required('Required'),
  pharmacyNotes: string().trim().max(140, 'Too Long'),
  dispensableDrugId: string().required('Required'),
})

export const ClientFormSchema = object()
  .shape({
    medicationSearch: mixed().when({
      is: (v) => !v,
      then: mixed().required('Required'),
      otherwise: SingleMedicationNameSchema,
    }),
    dispenseUnit: mixed().when({
      is: (v) => !v,
      then: mixed(),
      otherwise: number(),
    }),
    schedule: number().required('Required'),
  })
  .required('Required')
  .concat(BaseRxSchema)
  .concat(SingleStrengthSchema)

const QuerySchema = object().shape({
  visitId: string().required(),
  rx: object()
    .shape({
      pharmacyId: string().required(),
      memberId: string().required(),
      providerId: string().required(),
      unitsSourceId: mixed().required('Required'),
    })
    .concat(BaseRxSchema)
    .concat(SingleMedicationNameSchema)
    .concat(SingleStrengthSchema),
})

export default QuerySchema
