import { lazy } from 'react'
import { isSuccessVisit } from 'constants/visit-types'
import format from 'date-fns/format'

const ModalsMap = {
  autoLogout: {
    Component: lazy(() => import('./AutoLogout')),
    label: 'Still there?',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  addMedication: {
    Component: lazy(() => import('./AddMedication')),
    label: 'Add Prescription',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  editMedication: {
    Component: lazy(() => import('./EditMedication')),
    label: 'Edit Prescription',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  memberMedication: {
    Component: lazy(() => import('./MemberMedication')),
    label: 'Member Medication',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  memberFamilyHistory: {
    Component: lazy(() => import('./MemberFamilyHistory')),
    label: 'Member Family History',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  memberMedicalHistory: {
    Component: lazy(() => import('./MemberMedicalHistory')),
    label: 'Member Medical History',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  memberAllergy: {
    Component: lazy(() => import('./MemberAllergy')),
    label: 'Member Allergy',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  deleteMemberRecord: {
    Component: lazy(() => import('./DeleteMemberRecord')),
    label: 'Delete Member Record',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  addAddendum: {
    Component: lazy(() => import('./AddAddendum')),
    label: 'Add Addendum Note',
    titleCancelButtonVariant: false,
    rootClassName: 'addAddendum',
  },
  psychotherapyNotes: {
    Component: lazy(() => import('./PsychotherapyNotes')),
    label: '',
    titleCancelButtonVariant: false,
    rootClassName: 'psychotherapyNotes',
  },
  briefScheduling: {
    Component: lazy(() => import('./BriefScheduling')),
    label: ({ visit }) => `Scheduled ${isSuccessVisit(visit?.visitType) ? 'Session' : 'Visit'}`,
    titleCancelButtonVariant: 'X',
    rootClassName: '',
  },
  hidstgs: {
    Component: lazy(() => import('./HiddenSettings')),
    label: 'Hidden settings',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  patientSummary: {
    Component: lazy(() => import('./PatientSummary')),
    label: ({ visit }) => `${isSuccessVisit(visit?.visitType) ? 'Student Success' : 'Patient Chart'} Summary`,
    titleCancelButtonVariant: true,
    rootClassName: 'patientSummary',
  },
  cancelVisit: {
    Component: lazy(() => import('./CancelVisit')),
    label: ({ visit }) => `Cancel ${isSuccessVisit(visit?.visitType) ? 'session' : 'visit'}`,
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  suggestTimes: {
    Component: lazy(() => import('./SuggestTimes')),
    label: 'Suggest New Times',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  cancelScheduling: {
    Component: lazy(() => import('./CancelScheduling')),
    label: ({ visit }) => `Cancel ${isSuccessVisit(visit?.visitType) ? 'Session' : 'Visit'} Request?`,
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  phoneFallback: {
    Component: lazy(() => import('./PhoneFallback')),
    label: 'Call Failed',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  phoneSuccess: {
    Component: lazy(() => import('./PhoneSuccess')),
    label: 'Call Initiated',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  pdfViewer: {
    Component: lazy(() => import('./PDFViewer')),
    label: '',
    titleCancelButtonVariant: 'X',
    rootClassName: '',
  },
  confirmEndCall: {
    Component: lazy(() => import('./ConfirmEndCall')),
    label: '',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  chatHistory: {
    Component: lazy(() => import('./ChatHistory')),
    label: '',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  photosImage: {
    Component: lazy(() => import('./PhotosImage')),
    label: '',
    titleCancelButtonVariant: true,
    rootClassName: '',
  },
  changePharmacy: {
    Component: lazy(() => import('./ChangePharmacy')),
    label: 'Change Pharmacy',
    titleCancelButtonVariant: true,
    rootClassName: '',
  },
  cancelPrescription: {
    Component: lazy(() => import('./CancelPrescription')),
    label: '',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  denyChangeRequest: {
    Component: lazy(() => import('./DenyChangeRequest')),
    label: 'Deny request',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  confirmChangeRequest: {
    Component: lazy(() => import('./ConfirmChangeRequest')),
    label: '',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  phoneVerification: {
    Component: lazy(() => import('./PhoneVerification')),
    label: 'Phone Verification',
    titleCancelButtonVariant: true,
    rootClassName: '',
  },
  dayVisits: {
    Component: lazy(() => import('./DayVisits')),
    label: ({ dateString }) => format(new Date(dateString), 'EEEE, MMMM do'),
    titleCancelButtonVariant: 'X',
    rootClassName: '',
  },
  setAvailability: {
    Component: lazy(() => import('./SetAvailability')),
    label: 'Set Availability',
    titleCancelButtonVariant: false,
    rootClassName: 'setAvailability',
  },
  blackoutDates: {
    Component: lazy(() => import('./BlackoutDates')),
    label: 'Blackout Dates',
    titleCancelButtonVariant: false,
    rootClassName: 'blackoutDates',
  },
  forgotPassword: {
    Component: lazy(() => import('./ForgotPassword')),
    label: 'Forgot Password',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  reschedule: {
    Component: lazy(() => import('./Reschedule')),
    label: 'Reschedule visit',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  intakeResults: {
    Component: lazy(() => import('./IntakeResults')),
    label: 'Intake Results',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
  safetyPlan: {
    Component: lazy(() => import('./SafetyPlan')),
    label: 'Safety Plan',
    titleCancelButtonVariant: false,
    rootClassName: '',
  },
}

export default ModalsMap
