export const UNIT_OPTIONS = [
  'Tablet/Pill',
  'Tablet - Delayed Release',
  'Tablet - Extended Release ',
  'Suspension',
  'Capsule',
  'Powder',
  'Liquid',
  'Lotion',
  'Cream',
  'Solution',
  'Ointment',
  'Gel',
  'Foam',
  'Inhaler',
  'Spray',
  'Swab',
  'Soap',
]
export const UNIT_STRENGTH_OPTIONS = ['mg', 'ml', 'mcg', '%', 'mg/mL', 'mg/3mL', 'm/5mL', 'mcg/inhalation']
export const FREQUENCY_OPTIONS = [
  'Once a day',
  'Twice a day',
  'Three times a day',
  'Four times a day',
  'As needed',
  'Once every other day',
  'Once every 3 days',
  'Once a week',
  'Once every other week',
  'Once a month',
  'Other',
]
