import { object, string } from 'yup'

import { MedicalHistorySchema } from 'schemas/MedicalHistory'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  medicalHistory: MedicalHistorySchema,
})

export const url = ({ memberId }) => `/v2/history/personal/${memberId}`

export const getBody = ({ medicalHistory }) => ({ medicalHistory })
