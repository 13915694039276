import { makeObservable, action, runInAction, onBecomeObserved } from 'mobx'
import mergeMobxListsBy from 'util/mergeMobxListsBy'
import VisitsCollection from '../VisitsCollection'
import makeQuery from 'api/makeQuery'
import PendingVisit from '../visits/PendingVisit'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import formatISO from 'date-fns/formatISO'
import { API_FETCH_LIMIT, API_PAST_VISITS_FETCH_LIMIT } from 'constants/apiFetchLimits'

class PastVisitsCollection extends VisitsCollection {
  constructor(...args) {
    super(...args)
    this.memberId = (args.length && args[0].memberId) || ''
    this.memberVisitTypeFilter = args.length && args[0].memberVisitTypeFilter
    this.selectedDate = null
    this.page = 0
    this.endOfPage = false
    this.loading = false
    this.selectedPendingVisitId = ''
    makeObservable(this, {
      selectedDate: true,
      endOfPage: true,
      loading: true,
      page: true,
      selectedPendingVisitId: true,
      mergePendingVisits: action,
      incrementPage: action.bound,
      setSelectedDate: action.bound,
      toggleLoading: action.bound,
      setSelectedPendingVisitId: action.bound,
    })
    this.disposePastVisitsFetcher = onBecomeObserved(this, 'list', () => {
      if (this.memberId) {
        this.refetchByMemberId()
      }
    })
  }

  get canIncrementPage() {
    return !this.loading && !this.selectedPendingVisitId && !this.selectedDate && !this.endOfPage
  }

  destroy() {
    this.disposePastVisitsFetcher()
  }

  setSelectedDate(date) {
    if (date) {
      if (date != 'Invalid Date') {
        this.selectedDate = date
        this.refetchByDate()
      }
    } else {
      this.selectedDate = null
      this.refetch()
    }
  }

  setSelectedPendingVisitId(pendingVisitId) {
    this.selectedPendingVisitId = pendingVisitId
    if (this.selectedPendingVisitId) {
      if (/^([0-9a-fA-F]{24})$/.test(this.selectedPendingVisitId)) {
        this.refetchByPendingVisitId()
      }
    } else {
      this.refetch()
    }
  }

  incrementPage(refetchBy) {
    this.page = this.page + API_PAST_VISITS_FETCH_LIMIT
    switch (refetchBy) {
      case 'providerId':
        this.refetch()
        break
      case 'memberId':
        this.refetchByMemberId()
    }
  }

  toggleEndOfPage() {
    this.endOfPage = !this.endOfPage
  }

  toggleLoading() {
    this.loading = !this.loading
  }

  mergePendingVisits(pendingVisits, shouldDelete) {
    mergeMobxListsBy(this.map, this.list, pendingVisits, 'pendingVisitId', (v) => new PendingVisit(v), shouldDelete)
    this.list.sort((a, b) => new Date(b.scheduledAt) - new Date(a.scheduledAt))
  }

  async refetch() {
    this.toggleLoading()
    const result = await makeQuery('getPendingVisits', {
      filter: {
        provider_id: this.provider.providerId,
        status: ['completed'],
      },
      page: {
        skip: this.page,
        limit: API_PAST_VISITS_FETCH_LIMIT,
      },
      sort: {
        scheduled_at: -1,
      },
    })
    runInAction(() => {
      this.toggleLoading()
      if (result.paginated.length < API_PAST_VISITS_FETCH_LIMIT) {
        this.toggleEndOfPage()
      }
      this.mergePendingVisits(result.paginated, false)
    })
  }

  async refetchByPendingVisitId() {
    const result = await makeQuery('getPendingVisit', {
      visitId: this.selectedPendingVisitId,
    })
    runInAction(() => {
      this.mergePendingVisits([result], true)
      this.page = 0
    })
  }

  async refetchByMemberId() {
    this.toggleLoading()
    const filter = {
      member_id: this.memberId,
      status: ['completed'],
    }

    if (this.memberVisitTypeFilter) {
      filter.visit_type = this.memberVisitTypeFilter
    }

    const result = await makeQuery('getPendingVisits', {
      filter,
      page: {
        skip: this.page,
        limit: API_PAST_VISITS_FETCH_LIMIT,
      },
      sort: {
        scheduled_at: -1,
      },
    })
    runInAction(() => {
      this.toggleLoading()
      if (result.paginated.length < API_PAST_VISITS_FETCH_LIMIT) {
        this.toggleEndOfPage()
      }
      this.mergePendingVisits(result.paginated, false)
    })
  }

  async refetchByDate() {
    const result = await makeQuery('getPendingVisits', {
      filter: {
        provider_id: this.provider.providerId,
        status: ['completed'],
      },
      gt: {
        scheduled_at: formatISO(startOfDay(this.selectedDate)),
      },
      lt: {
        scheduled_at: formatISO(endOfDay(this.selectedDate)),
      },
      page: {
        skip: 0,
        limit: API_FETCH_LIMIT,
      },
      sort: {
        scheduled_at: -1,
      },
    })
    runInAction(() => {
      this.mergePendingVisits(result.paginated, true)
      this.page = 0
    })
  }
}

export default PastVisitsCollection
