import { lazy } from 'react'
import format from 'date-fns/format'
import { isSuccessVisit } from 'constants/visit-types'

const V2ModalsMap = {
  dayVisitsV2: {
    label: ({ dateString }) => format(new Date(dateString), 'EEEE, MMMM do'),
    Content: lazy(() => import('../v2Modals/DayVIsits')),
  },
  rescheduleVisit: {
    label: 'Reschedule Visit',
    Content: lazy(() => import('../v2Modals/RescheduleVisit')),
  },
  briefSchedulingV2: {
    label: ({ visit }) => `Scheduled ${isSuccessVisit(visit?.visitType) ? 'Session' : 'Visit'}`,
    Content: lazy(() => import('../v2Modals/BriefScheduling/Content')),
    Footer: lazy(() => import('../v2Modals/BriefScheduling/Footer')),
  },
  timeSlotSave: {
    label: 'Time Slots Saved',
    Content: lazy(() => import('../v2Modals/TimeSlotSave/Content')),
    Footer: lazy(() => import('../v2Modals/TimeSlotSave/Footer')),
  },
  unsavedTimeSlots: {
    label: 'Unsaved Changes',
    Content: lazy(() => import('../v2Modals/UnsavedTimeSlots/Content')),
    Footer: lazy(() => import('../v2Modals/UnsavedTimeSlots/Footer')),
  },
  memberMedicationV2: {
    label: ({ medicationId }) => `${medicationId ? 'Edit' : 'Add'} Medication`,
    Content: lazy(() => import('../v2Modals/MemberMedication/Content')),
    Footer: lazy(() => import('../v2Modals/MemberMedication/Footer')),
    Container: lazy(() => import('../v2Modals/MemberMedication/Container')),
    footerVariant: 'right',
  },
} as const

export type V2ModalMapType = keyof typeof V2ModalsMap

export default V2ModalsMap
