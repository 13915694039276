import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import getObjectTransformer from 'util/getObjectTransformer'
import getDeepestData from 'util/getDeepestData'

import axios from 'axios'
import PendingQueries from 'singletons/PendingQueries'
import User from 'singletons/User'

import Requests, { ApiQueriesSuccessfulResponseTypes, ApiQueriesRequestTypes } from './requests/cooked/queries'

import reportError from 'util/reportError'
import { ValidationError } from 'yup'
import parseBackendErrors from 'util/parseBackendErrors'

export const fromApi = getObjectTransformer(camelCase)

const convertEmptyStringToNull = (v: string) => (v === '' ? null : v)

/*
  _id is for mongo;
*/
export const toApiNormal = getObjectTransformer(
  (k: string) => (k === 'id' ? '_id' : snakeCase(k)),
  convertEmptyStringToNull,
)

const toApiWithoutSnake = getObjectTransformer((k: string) => (k === 'id' ? '_id' : k), convertEmptyStringToNull)

async function makeQuery<T extends keyof typeof Requests>(
  fetchType: T,
  params: ApiQueriesRequestTypes[T],
  tokenOption = 'default',
): Promise<ApiQueriesSuccessfulResponseTypes[T]> {
  const {
    url: getUrl,
    method,
    getBody,
    baseURL,
    Schema,
    doNotConvertKeysToSnakeCase,
    responseType,
  } = Requests[fetchType]

  /*
    actions should not be called inside component observers.
    makeQuery is often used in pair with onBecomeObserved, which happens inside render.
    to avoid react errors, PendingQueries.add must be performed asynchronously
  */

  let queryId

  try {
    await Schema.validate(params)
    queryId = PendingQueries.add(fetchType, params)
    const toApi = doNotConvertKeysToSnakeCase ? toApiWithoutSnake : toApiNormal
    let token
    if (tokenOption === 'mfa') {
      token = User.mfa.token
    } else {
      token = User.auth.token
    }
    const body = await getBody.call(undefined, params)
    const response = await axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: token && `Bearer ${token}`,
      },
      method,
      baseURL,
      responseType,
      url: getUrl.call(undefined, params),
      // must return undefined for axios and null for fetch to indicate body is empty
      data: body ? toApi(body) : undefined,
    })
    return fromApi(getDeepestData(response))
  } catch (e) {
    if (e instanceof ValidationError) {
      console.warn('Query params: ', params, 'Error path: ', e.path)
      console.dir(e)
      reportError('PROMISE', `Error during query validation: ${fetchType}`)
    } else throw parseBackendErrors(e)
  } finally {
    PendingQueries.remove(fetchType, queryId)
  }
}

export default makeQuery
